import * as React from "react"

import AppStoreBadge from "../svg/stores/appstore.svg"
import PlayStoreBadge from "../svg/stores/googleplay_alt.svg"

export const StoreBadgeApple = "appstore"
export const StoreBadgeGoogle = "playstore"

type Props = {
  type: typeof StoreBadgeApple | typeof StoreBadgeGoogle
  height?: number
  borderRadius?: number
}

const StoreBadge: React.FC<Props> = ({ type, height, borderRadius }) =>
  type === StoreBadgeApple ? (
    <a
      href="https://apps.apple.com/app/apple-store/id1615495712?pt=118008813&ct=Website&mt=8"
      target="_blank"
      title="Download on the App Store"
    >
      <AppStoreBadge
        height={height || 90}
        fill="#fff"
        style={{
          background: "#333",
          padding: 5,
          borderRadius: borderRadius || 20,
          margin: 10,
        }}
      />
    </a>
  ) : (
    <a
      href="https://play.google.com/store/apps/details?id=com.caplaz.doodle"
      target="_blank"
      title="Get it on Google Play"
    >
      <PlayStoreBadge
        height={height || 90}
        fill="#fff"
        style={{
          background: "#333",
          padding: 5,
          borderRadius: borderRadius || 20,
          margin: 10,
        }}
      />
    </a>
  )

export default StoreBadge
