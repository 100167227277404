import * as React from "react"
import StoreBadge from "./badge"

import { LocalizationProps, t } from "../utils/localization"

const DownloadSection: React.FC<LocalizationProps> = ({ lang }) => (
  <section id="download">
    <div className="container text-center">
      <h2>
        {t(
          {
            en: "Share the fun with Doodle+",
            it: "Condividi il divertimento con Doodle+",
          },
          lang
        )}
      </h2>
      <div className="title-block">
        <p>
          {t(
            {
              en: "Doodle+ can be additive, no registration is required, just open the app pick a color and start painting, each drawing has a unique code that can be used to load your canvas at a later time.",
              it: "Doodle+ può essere additivo, non è richiesta alcuna registrazione, basta aprire l'app, scegliere un colore e iniziare a dipingere, ogni disegno ha un codice univoco che può essere utilizzato per caricare la tua tela in un secondo momento.",
            },
            lang
          )}
        </p>
        <StoreBadge type={"appstore"} height={60} borderRadius={10} />
        <StoreBadge type={"playstore"} height={60} borderRadius={10} />
      </div>
    </div>
  </section>
)

export default DownloadSection
