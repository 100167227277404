import * as React from "react"
import { Helmet } from "react-helmet"
import { GatsbyImage, IGatsbyImageData, StaticImage } from "gatsby-plugin-image"
import { graphql, PageProps } from "gatsby"

import DownloadSection from "../components/download"
import Layout from "../components/layouts"
import Seo from "../components/seo"

import { LANG_IT } from "../utils/localization"
import { SiteInformation } from "../utils/site"

type Props = SiteInformation & {
  allFile: {
    edges: [
      {
        node: {
          childImageSharp: {
            gatsbyImageData: IGatsbyImageData
            resize: {
              src: string
              originalName: string
            }
          }
        }
      }
    ]
  }
}

const IndexPage: React.FC<PageProps<Props>> = ({ data, path }) => (
  <Layout>
    <Helmet>
      <link
        rel="alternate"
        hrefLang={"x-default"}
        href={`${data.site.siteMetadata.siteUrl}/`}
      />
      <link
        rel="alternate"
        hrefLang={LANG_IT}
        href={`${data.site.siteMetadata.siteUrl}/it/`}
      />
    </Helmet>
    <Seo
      title="Home Page"
      description="Doodle+ is an amazing simple drawing app that is bringing back the joy of painting in your free time, solo or with some friends."
      path={path}
    />
    <section id="hero">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
            <StaticImage
              src="../images/iphone.png"
              width={450}
              placeholder="none"
              className="img-fluid"
              alt="App Homepage"
            />
          </div>
          <div className="col-md-7 content-box hero-content">
            <span>{data.site.siteMetadata.title}</span>
            <h1>
              The more creative you are trying to be,{" "}
              <b>the more creative you will be!</b>
            </h1>
            <p>
              Doodle+ is an amazing simple drawing app that is bringing back the
              joy of painting in your free time, regardless if you want to do it
              solo and enjoy the doodle fun with some friends.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="marketing">
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <div className="content-box">
              <span>Features</span>
              <h2>Collaboration</h2>
              <p>
                You can share the unique code and start to collaborate on your
                drawing with friends in real-time. There is no limit to what you
                can make, it's amazing to see your artwork created like magic.
              </p>
              &nbsp;
              <ul>
                <li>
                  <i className="icon ion-md-checkmark-circle-outline" />{" "}
                  User-friendly interface to pick your color and brush size
                </li>
                <li>
                  <i className="icon ion-md-checkmark-circle-outline" /> No
                  registration is required, just download the app and start
                  drawing
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-7">
            <div className="content-box photo-gallery">
              <div className="row photos">
                {data.allFile.edges.map(({ node }) => (
                  <div
                    key={node.childImageSharp.resize.originalName}
                    className="col-sm-3 col-md-4 col-lg-4 item text-center"
                  >
                    <a
                      href={node.childImageSharp.resize.src}
                      data-lightbox="screenshots"
                    >
                      <GatsbyImage
                        image={node.childImageSharp.gatsbyImageData}
                        alt={node.childImageSharp.resize.originalName}
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <section id="testimonials">
      <div className="container">
        <div className="title-block">
          <h2>The Best Digital Agencies Recommend Our Software</h2>
          <p>
            Industry experts mention their experience using our software and the
            excellent results they have achieved
          </p>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="testimonial-box">
              <div className="row personal-info">
                <div className="col-md-2 col-xs-2">
                  <div className="profile-picture review-one"></div>
                </div>
                <div className="col-md-10 col-xs-10">
                  <h6>
                    Joshua M. Salas{" "}
                    <span className="rating">
                      5 <i className="icon ion-md-star"></i>
                    </span>
                  </h6>
                  <small>
                    Marketing Intelligence | Author &amp; Content Creator
                  </small>
                </div>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur scelerisque, tortor nec mattis feugiat, velit purus
                euismod odio, quis vulputate velit urna sit amet enim. Maecenas
                vulputate auctor ligula sed sollicitudin.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="testimonial-box">
              <div className="row personal-info">
                <div className="col-md-2 col-xs-2">
                  <div className="profile-picture review-one"></div>
                </div>
                <div className="col-md-10 col-xs-10">
                  <h6>
                    Michael Edwards{" "}
                    <span className="rating">
                      5 <i className="icon ion-md-star"></i>
                    </span>
                  </h6>
                  <small>Seo Consultant | Author &amp; Content Creator</small>
                </div>
              </div>
              <p>
                In euismod, metus ac elementum tincidunt, dui eros ullamcorper
                lorem, at euismod augue augue quis leo. Fusce non dui augue. In
                hac habitasse platea dictumst. Mauris quis lacinia mauris. Proin
                ut pretium quam. Nam at ex finibus.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section> */}
    <DownloadSection />
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    site {
      ...SiteInformation
    }
    allFile(
      filter: { sourceInstanceName: { eq: "screenshots" } }
      sort: { fields: childImageSharp___resize___originalName }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(width: 150)
            resize(width: 500) {
              src
              originalName
            }
          }
        }
      }
    }
  }
`
